import React, { useState, useEffect } from "react";
import { instance } from "index";
import { useSelector } from "react-redux";
import moment from "moment";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import ScrollButton from "components/common/ScrollButton";
import Tooltip from "../../../../common/Tooltip";
import { IoLocationOutline } from "react-icons/io5";
import { FaRegBuilding, FaLayerGroup } from "react-icons/fa";
import Loader3 from "components/common/Loader3";
import InfiniteScroll from "react-infinite-scroll-component";

export default function Leads() {
  const [value, setValue] = useState("");
  const [currentTab, setCurrentTab] = useState("Active");
  const { auth } = useSelector((state) => state);
  const { access } = useSelector((state) => state.auth.userInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [leads, setLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [hasMore, setHasMore] = useState();
  const leadsPerPage = 1000;

  const platforms = [
    { value: "kimeta", label: "kimeta" },
    { value: "xing", label: "Xing" },
    { value: "jobsinmilan", label: "JobsInMilan" },
    { value: "glassdoor", label: "Glassdoor" },
    { value: "StepStone", label: "StepStone" },
    { value: "Monster", label: "Monster" },
    { value: "Jobsite", label: "Jobsite" },
    { value: "Remote.co", label: "Remote.co" },
    { value: "startup.jobs", label: "Startup.jobs" },
    { value: "CareerJet", label: "CareerJet" },
    { value: "NaukriGulf", label: "NaukriGulf" },
    { value: "TotalJobs", label: "TotalJobs" },
    { value: "angel.co", label: "Angel.co" },
    { value: "Zarplata.ru", label: "Zarplata.ru" },
    { value: "GigaJob.gm", label: "GigaJob.gm" },
    { value: "SkipTheDrive", label: "SkipTheDrive" },
    { value: "LocalJobsNetwork", label: "LocalJobsNetwork" },
    { value: "ReedCo", label: "ReedCo" },
    { value: "DynamiteJobs", label: "DynamiteJobs" },
    { value: "JobIsJob.us", label: "JobIsJob.us" },
    { value: "LandingJobs", label: "LandingJobs" },
    { value: "workopolis.com", label: "Workopolis.com" },
    { value: "Happy Remote", label: "Happy Remote" },
    { value: "CareerBuilder", label: "CareerBuilder" },
    { value: "Highered Jobs.us", label: "Highered Jobs.us" },
    { value: "AuthenticJobs", label: "AuthenticJobs" },
    { value: "hh.ru", label: "hh.ru" },
    { value: "ResumeLibrary", label: "ResumeLibrary" },
    { value: "Jobing.us", label: "Jobing.us" },
    { value: "Dice", label: "Dice" },
    { value: "JustRemote.Remote", label: "JustRemote.Remote" },
    { value: "gotogulf", label: "Gotogulf" },
    { value: "Job 2 Careers.us", label: "Job 2 Careers.us" },
    { value: "AkhtaBoot", label: "AkhtaBoot" },
    { value: "GulfTalent", label: "GulfTalent" },
    { value: "EngineeringJobs.Remote", label: "EngineeringJobs.Remote" },
    { value: "NewZealand Recruit", label: "NewZealand Recruit" },
    { value: "JobMonitor", label: "JobMonitor" },
    { value: "Jobomas", label: "Jobomas" },
    { value: "cari", label: "Cari" },
    { value: "recruit.net", label: "Recruit.net" },
    { value: "maukerja.my", label: "Maukerja.my" },
    { value: "JobStreet", label: "JobStreet" },
    { value: "Job Rapido", label: "Job Rapido" },
    { value: "jobstore.com", label: "Jobstore.com" },
    { value: "JobsInStockholm", label: "JobsInStockholm" },
    { value: "Bayt", label: "Bayt" },
    { value: "Adzuna", label: "Adzuna" },
    { value: "Eluta", label: "Eluta" },
    { value: "Jobs.ch", label: "Jobs.ch" },
    { value: "JobSora", label: "JobSora" },
    { value: "BuiltInNYC", label: "BuiltInNYC" },
    { value: "Vdab", label: "Vdab" },
    { value: "JobsInIt", label: "JobsInIt" },
    { value: "JobbSafari", label: "JobbSafari" },
    { value: "JobForGeek", label: "JobForGeek" },
    { value: "CaTalent", label: "CaTalent" },
    { value: "SimplyHired", label: "SimplyHired" },
    { value: "RemoteOK", label: "RemoteOK" },
    { value: "Kariyer", label: "Kariyer" },
    { value: "AI Jobs", label: "AI Jobs" },
    { value: "JobUp.ch", label: "JobUp.ch" },
    { value: "remoters", label: "Remoters" },
    { value: "topusajobs", label: "Top USA Jobs" },
    { value: "remotive", label: "Remotive" },
    { value: "SnagaJob", label: "SnagaJob" },
    { value: "WeWorkRemotely", label: "WeWorkRemotely" },
    { value: "RengineeringJobs", label: "RengineeringJobs" },
    { value: "ziprecruiter", label: "Ziprecruiter" },
    { value: "Seek.co.au", label: "Seek.co.au" },
    { value: "jobinventory", label: "Jobinventory" },
    { value: "Venture Loop", label: "Venture Loop" },
    { value: "techfetch", label: "Techfetch" },
    { value: "theladders", label: "TheLadders" },
    { value: "Upward.careers", label: "Upward.careers" },
    { value: "jooble.org", label: "Jooble.org" },
    { value: "jobvertise", label: "Jobvertise" },
    { value: "TheGuardian", label: "The Guardian" },
    { value: "JobFluent", label: "JobFluent" },
    { value: "Jobs In Network", label: "Jobs In Network" },
    { value: "europa.eu", label: "Europa.eu" },
    { value: "Karriere", label: "Karriere" },
    { value: "WowJobs", label: "WowJobs" },
    { value: "PostJobFree", label: "PostJobFree" },
    { value: "Jobillico", label: "Jobillico" },
    { value: "Blockace.io", label: "Blockace.io" },
    { value: "Welcome to the Jungle", label: "Welcome to the Jungle" },
    { value: "Blockchain Jobs Europe", label: "Blockchain Jobs Europe" },
    { value: "workinstartups", label: "WorkInStartups" },
  ];

  const getFilteredLeads = async (userId, filterType) => {
    const newOffset = (currentPage - 1) * leadsPerPage;

    setIsLoading(true);
    try {
      const filterQuery =
        filterType === "Archived"
          ? "?is_archived=true"
          : filterType === "Applied"
          ? "?is_applied=true"
          : "?is_archived=false&is_applied=false";

      const regionQuery = selectedRegion ? `&region=${selectedRegion}` : "";
      const platformQuery = selectedPlatform
        ? `&platform=${selectedPlatform}`
        : "";
      const searchQuery = value ? `&search=${value}` : "";

      const result = await instance.get(
        `job-leads/${filterQuery}${regionQuery}${platformQuery}${searchQuery}&offset=${newOffset}&limit=${leadsPerPage}&ordering=${`posted_date_formatted`}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );

      if (result?.status === 200) {
        let results = result?.data?.results;

        let filteredLeads = [];
        if (currentTab === "Buggy_leads") {
          filteredLeads = results.filter((lead) => lead.lead_url === null);
        } else {
          filteredLeads = results.filter((lead) => lead.lead_url !== null);
        }

        setLeads((prevLeads) =>
          currentPage === 1 ? filteredLeads : [...prevLeads, ...filteredLeads]
        );

        setHasMore(filteredLeads?.data?.results?.length > 0);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  const fetchMoreData = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleReset = () => {
    setSelectedRegion("");
    setSelectedPlatform("");
    setValue("");
  };

  useEffect(() => {
    if (auth?.userInfo?.user?.id) {
      getFilteredLeads(auth?.userInfo?.user?.id, currentTab);
    }
  }, [
    auth?.userInfo?.user?.id,
    currentTab,
    currentPage,
    selectedPlatform,
    selectedRegion,
    value,
  ]);

  const handleArchivedLead = async (leadId) => {
    try {
      const response = await instance.patch(
        `job-leads/${leadId}/`,
        { is_archived: true },
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Job archived successfully!");
        setLeads((prevLeads) => prevLeads.filter((lead) => lead.id !== leadId));
      }
    } catch (error) {
      console.log("Error archiving the lead:", error);
    }
  };

  const handleApplyLead = async (leadId) => {
    try {
      const response = await instance.patch(
        `job-leads/${leadId}/`,
        { is_applied: true },
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );

      if (response.status === 200) {
        setLeads((prevLeads) => prevLeads.filter((lead) => lead.id !== leadId));
      }
    } catch (error) {
      console.log("Error applying for the lead:", error);
    }
  };

  const handleRemoveArchivedLead = async (leadId) => {
    try {
      const response = await instance.patch(
        `job-leads/${leadId}/`,
        { is_archived: false },
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Restore job successfully!");
        setLeads((prevLeads) => prevLeads.filter((lead) => lead.id !== leadId));
      }
    } catch (error) {
      console.log("Error removing from archived:", error);
    }
  };

  const handleRemoveAppliedLead = async (leadId) => {
    try {
      const response = await instance.patch(
        `job-leads/${leadId}/`,
        { is_applied: false },
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Withdraw Application successfully!");
        setLeads((prevLeads) => prevLeads.filter((lead) => lead.id !== leadId));
      }
    } catch (error) {
      console.log("Error removing from applied:", error);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [currentTab]);

  return (
    <>
      <div className="scroll-top opacity">
        <ScrollButton />
      </div>
      <div className="myTime d-flex justify-content-between mb-1 align-items-center">
        <h6 className="text-white PaytableHeading">Leads Dashboard</h6>
      </div>
      <div
        className="col-12 col-xl-6 sidebar-search d-flex justify-content-between mb-1"
        style={{ width: "100%" }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          style={{
            display: "flex",
            backgroundColor: "#383B3F",
            width: "100%",
            borderRadius: "20px",
            alignItems: "center",
          }}
        >
          <input
            type="search"
            name="search"
            className="px-3 flex-grow-1"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            placeholder="Search Here"
            style={{ backgroundColor: "#383B3F" }}
          />
          <select
            className="flex-grow-1 lead_selector"
            value={selectedRegion || ""}
            onChange={(e) => setSelectedRegion(e.target.value)}
          >
            <option value="" defaultChecked>
              Select Region
            </option>
            <option value="Hybrid">Hybrid</option>
            <option value="Remote">Remote</option>
          </select>
          <select
            className="flex-grow-1 lead_selector"
            value={selectedPlatform || ""}
            onChange={(e) => setSelectedPlatform(e.target.value)}
          >
            {" "}
            <option value="">Select Platform</option>
            {platforms.map((platform) => (
              <option key={platform.value} value={platform.value}>
                {platform.label}
              </option>
            ))}
          </select>
          <span
            className=""
            style={{
              width: "20%",
              borderLeft: "1px solid rgb(255, 255, 255)",
              outline: "none",
              color: "white",
              cursor: "pointer",
              padding: '15px 20px'
            }}
            onClick={handleReset}
          >
            Reset
          </span>
        </form>
      </div>
      <div className="chooser col-md-7 m-auto mt-4" style={{ width: "100%" }}>
        <ul className="list-inline highlighted_underline">
          <li
            className={`${
              currentTab === "Active" ? "tab_active" : ""
            } middle_btn`}
            onClick={() => setCurrentTab("Active")}
          >
            Active
          </li>
          <li
            className={`${
              currentTab === "Archived" ? "tab_active" : ""
            } middle_btn`}
            onClick={() => setCurrentTab("Archived")}
          >
            Archived
          </li>
          <li
            className={`${
              currentTab === "Applied" ? "tab_active" : ""
            } middle_btn`}
            onClick={() => setCurrentTab("Applied")}
          >
            Applied
          </li>
          <li
            className={`${
              currentTab === "Buggy_leads" ? "tab_active" : ""
            } middle_btn`}
            onClick={() => setCurrentTab("Buggy_leads")}
          >
            Buggy Leads
          </li>
        </ul>
        <div className="leads_row">
          <span style={{ padding: "10px 0", margin: 0 }}>
            <hr
              className="m-0 p-0"
              style={{ background: "red", height: "2px" }}
            />
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {isLoading && currentPage === 1 ? (
            <Loader3 />
          ) : leads && leads?.length > 0 ? (
            <Container>
              <InfiniteScroll
                dataLength={leads.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<Loader3 />}
                endMessage={
                  <div
                    style={{
                      fontSize: "larger",
                      textAlign: "center",
                      padding: "10px 0px",
                    }}
                  >
                    No more leads
                  </div>
                }
              >
                <div className="p-4 text-white text-center">
                  {currentTab === "Buggy_leads" && leads?.length > 0 ? (
                    <>Bug in that leads their lead_url is null</>
                  ) : (
                    <></>
                  )}
                </div>
                {leads.map((job, index) => (
                  <>
                    <Row key={index} className="leads_row my-3">
                      <Col md={8} className="m-0">
                        <a
                          href={job?.lead_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="h5"
                          style={{ color: "red" }}
                        >
                          {job?.title}
                        </a>
                        <p className="mb-0 text-light">
                          {job.regoin !== null && (
                            <Tooltip content="Region">
                              <IoLocationOutline /> <span>{job?.region}</span>
                            </Tooltip>
                          )}{" "}
                          {job.platform !== null && (
                            <Tooltip
                              content="Platform"
                              className="d-flex align-items-center"
                            >
                              <FaLayerGroup /> <span>{job?.platform}</span>
                            </Tooltip>
                          )}{" "}
                          {job?.company !== null && (
                            <>
                              <a
                                href={job?.lead_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-light"
                              >
                                <Tooltip content="Company">
                                  <FaRegBuilding /> <span>{job?.company}</span>
                                </Tooltip>
                              </a>
                            </>
                          )}
                        </p>
                      </Col>
                      <Col
                        md={4}
                        className="d-flex align-items-center justify-content-end gap-2 m-0"
                      >
                        <span
                          className="text-light small"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {moment(job?.lead_date).fromNow()}
                        </span>
                        {job?.is_archived ? (
                          <div className="cmn-btn">
                            <button
                              className="mb-0 mt-1"
                              onClick={() => handleRemoveArchivedLead(job?.id)}
                            >
                              Restore
                            </button>
                          </div>
                        ) : (
                          <div className="cmn-btn">
                            <button
                              className="mb-0 mt-1"
                              onClick={() => handleArchivedLead(job?.id)}
                            >
                              Archive
                            </button>
                          </div>
                        )}

                        {job?.is_applied ? (
                          <div className="cmn-btn">
                            <button
                              className="mb-0 mt-1"
                              onClick={() => handleRemoveAppliedLead(job?.id)}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Withdraw Application
                            </button>
                          </div>
                        ) : (
                          <div className="cmn-btn">
                            <a
                              href={job?.lead_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-light"
                              onClick={() => handleApplyLead(job?.id)}
                            >
                              Apply
                            </a>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <div className="leads_row">
                      <span style={{ padding: "10px 0", margin: 0 }}>
                        <hr
                          className="m-0 p-0"
                          style={{ background: "red", height: "2px" }}
                        />
                      </span>
                    </div>
                  </>
                ))}
              </InfiniteScroll>
            </Container>
          ) : (
            <h3 className="d-flex justify-content-center mt-5">
              No leads available
            </h3>
          )}
        </div>
      </div>
    </>
  );
}
